@import "definitions";

$invoice_heading_font_size: 1.5em;                               

.invoice_editor {

    &-top {
        display: flex;

        &>div {
            flex: 1;    
            display:flex;
            flex-direction: column;        
        }

        &_heading {
            font-size: $invoice_heading_font_size;
            background: $theme_color;
            color: $theme_font_color;
            padding: $pad_small;
            margin-bottom: $mar_small;
            border-right: $border_light;
        }
        
        &_body {
            padding: $pad;
            border: $border;
            flex: 1;
        }

        &>div:not(:last-child) &_body {
            border-right: none;
        }
    }

}